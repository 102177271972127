<template>
  <div>
    <div class="header"></div>
    <audio ref="shutter">
      <source src="../../assets/shutter.mp3" type="audio/mpeg" />
    </audio>
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="color: #fff">
          <h4 class="text-center">Daftar Mandiri</h4>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="card" style="border-radius: 15px">
            <div style="text-align: center; padding: 20px"></div>
            <div class="container">
              <div class="row">
                <div
                  class="col-md-6"
                  :class="{
                    'col-md-9':
                      form.identity_status == '3' || form.identity_status == '',
                  }"
                >
                  <div class="form-group">
                    <label for="customer_name">Nama: </label>
                    <input
                      v-model="form.customer_name"
                      type="text"
                      class="form-control"
                      placeholder="Nama"
                      required
                      @input="formValidate.customer_name = ''"
                      :class="{
                        'is-valid': form.customer_name != '',
                        'is-invalid':
                          formValidate.customer_name ||
                          form.customer_name == '',
                      }"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="identity_status">Jenis Identitas : </label>
                    <b-input-group>
                      <b-form-select
                        v-model="form.identity_status"
                        name="identity_status"
                        id="identity_status"
                        @change="formValidate.identity_status = ''"
                        :class="{
                          'is-valid': form.identity_status != '',
                          'is-invalid':
                            formValidate.identity_status ||
                            form.identity_status == '',
                        }"
                      >
                        <option value="">Pilih Jenis Identitas</option>
                        <option value="1">
                          NIK (Nomor Induk Kependudukan)
                        </option>
                        <option value="2">NIB (Nomor Induk Berusaha)</option>
                        <option value="3">Lainnya</option>
                      </b-form-select>
                      <b-input-group-append>
                        <b-tooltip
                          target="tooltip-jenis"
                          triggers="hover focus"
                          placement="top"
                        >
                          <ul
                            style="
                              text-align: left;
                              margin: 0;
                              padding: 5px 5px 5px 15px;
                            "
                          >
                            <li>NIK Khusus untuk perorangan</li>
                            <li>NIB Khusus untuk usaha yang memiliki NIB</li>
                          </ul>
                        </b-tooltip>
                        <b-input-group-text id="tooltip-jenis">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                    <small
                      class="text-danger"
                      v-html="formValidate.identity_status"
                    ></small>
                  </div>
                </div>
                <div
                  class="col-md-3"
                  v-if="
                    form.identity_status == '1' || form.identity_status == '2'
                  "
                >
                  <div class="form-group">
                    <label for="identity_number">
                      {{ form.identity_status == "1" ? "NIK :" : "NIB :" }}
                    </label>
                    <input
                      v-model="form.identity_number"
                      type="number"
                      class="form-control"
                      :placeholder="
                        form.identity_status === '1' ? 'NIK' : 'NIB'
                      "
                      required
                      @input="validasiNik()"
                      :class="{
                        'is-valid': form.identity_number != '',
                        'is-invalid':
                          formValidate.identity_number ||
                          form.identity_number == '',
                      }"
                    />
                    <small
                      class="text-danger"
                      v-html="formValidate.identity_number"
                    ></small>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="customer_address">Alamat: </label>
                    <textarea
                      v-model="form.customer_address"
                      class="form-control"
                      name="customer_address"
                      id="customer_address"
                      placeholder="Alamat"
                      rows="2"
                      required
                      @input="formValidate.customer_address = ''"
                      :class="{
                        'is-valid': form.customer_address != '',
                        'is-invalid':
                          formValidate.customer_address ||
                          form.customer_address == '',
                      }"
                    ></textarea>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="district">Kecamatan : </label>
                    <select
                      v-model="form.district"
                      name="district"
                      id="district"
                      v-on:change="fetchVillages($event)"
                      class="form-control"
                      @change="formValidate.district = ''"
                      :class="{
                        'is-valid': form.district != '',
                        'is-invalid':
                          formValidate.district || form.district == '',
                      }"
                    >
                      <option value="">Pilih</option>
                      <option
                        v-for="districts in districtList"
                        :value="districts.id_kec"
                        :key="districts.id_kec"
                      >
                        {{ districts.nama_kecamatan }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="village">Kelurahan : </label>
                    <select
                      v-model="form.village"
                      name="village"
                      id="village"
                      class="form-control"
                      @change="formValidate.village = ''"
                      :class="{
                        'is-valid': form.village != '',
                        'is-invalid':
                          formValidate.village || form.village == '',
                      }"
                    >
                      <option value="">Pilih</option>
                      <option
                        v-for="villages in villageList"
                        :value="villages.id_kel"
                        :key="villages.id_kel"
                      >
                        {{ villages.nama_kelurahan }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="hamlet">RW : </label>
                    <input
                      v-model="form.hamlet"
                      type="number"
                      class="form-control"
                      name="hamlet"
                      placeholder="Contoh : 001"
                      @input="formValidate.hamlet = ''"
                      :class="{
                        'is-valid': form.hamlet != '',
                        'is-invalid': formValidate.hamlet || form.hamlet == '',
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="neighbourhood">RT : </label>
                    <input
                      v-model="form.neighbourhood"
                      type="number"
                      class="form-control"
                      name="neighbourhood"
                      placeholder="Contoh : 001"
                      @input="formValidate.neighbourhood = ''"
                      :class="{
                        'is-valid': form.neighbourhood != '',
                        'is-invalid':
                          formValidate.neighbourhood ||
                          form.neighbourhood == '',
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-2" v-if="form.identity_status == '1'">
                  <div class="form-group">
                    <label for="employment_status">Status ASN/Sipil : </label>
                    <select
                      v-model="form.employment_status"
                      name="employment_status"
                      id="employment_status"
                      @change="
                        formValidate.employment_status = '';
                        form.employment_status === '1'
                          ? (form.customer_categories =
                              '6fece19d-7f0d-4ab9-ab91-53f207fe979b')
                          : (form.customer_categories = '');
                      "
                      class="form-control"
                      :class="{
                        'is-valid': form.employment_status != '',
                        'is-invalid':
                          formValidate.employment_status ||
                          form.employment_status == '',
                      }"
                    >
                      <option value="">Pilih</option>
                      <option value="1">ASN Kota Palu</option>
                      <option value="2">ASN Non Kota Palu</option>
                      <option value="3">Sipil</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3" v-if="form.employment_status == '1'">
                  <div class="form-group">
                    <label for="institutions">Instansi : </label>
                    <select
                      v-model="form.institutions"
                      class="form-control"
                      name="institutions"
                      required
                      @change="formValidate.institutions = ''"
                      :class="{
                        'is-valid': form.institutions != '',
                        'is-invalid':
                          formValidate.institutions || form.institutions == '',
                      }"
                    >
                      <option value="">Pilih Instansi</option>
                      <option
                        v-for="instansi in instansiList"
                        :key="instansi.government_agency_id"
                        :value="instansi.government_agency_id"
                      >
                        {{ instansi.government_agency_name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="customer_categories">Jenis Kegiatan : </label>
                    <select
                      v-model="form.customer_categories"
                      class="form-control"
                      name="customer_categories"
                      required
                      @change="formValidate.customer_categories = ''"
                      :class="{
                        'is-valid': form.customer_categories != '',
                        'is-invalid':
                          formValidate.customer_categories ||
                          form.customer_categories == '',
                      }"
                    >
                      <option value="">Pilih Jenis Kegiatan</option>
                      <option
                        v-for="categories in categoiresList"
                        :key="categories.customer_category_id"
                        :value="categories.customer_category_id"
                      >
                        {{ categories.category_name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="phone_number">Nomor HP/WA : </label>
                    <input
                      v-model="form.phone_number"
                      type="tel"
                      class="form-control"
                      name="phone_number"
                      placeholder="Nomor HP/WA"
                      @input="formValidate.phone_number = ''"
                      :class="{
                        'is-valid': form.phone_number != '',
                        'is-invalid':
                          formValidate.phone_number || form.phone_number == '',
                      }"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="institutions">Foto Depan Rumah : </label>
                    <button
                      v-if="!cameraTwoOpen"
                      type="button"
                      class="btn btn-block btn-warning"
                      :class="{
                        'btn-danger': formValidate.image_house,
                      }"
                      @click="startCamera(2)"
                    >
                      <span><i class="fa fa-camera"></i> Buka Kamera</span>
                    </button>
                    <button
                      v-else
                      type="button"
                      class="btn btn-block btn-dark"
                      @click="stopCamera(2)"
                    >
                      <span><i class="fa fa-times"></i> Tutup Kamera</span>
                    </button>
                    <small
                      class="text-danger"
                      v-html="formValidate.image_house"
                    ></small>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div v-show="kameraFotoSketsa" class="mt-2 col-md-12">
                      <canvas ref="canvasSketsa" style="display: none"></canvas>
                      <video
                        style="
                          width: 100%;
                          border-radius: 10px;
                          overflow: hidden;
                        "
                        v-show="kameraFotoSketsa"
                        ref="kameraSketsa"
                        autoplay
                      ></video>
                      <div
                        v-if="cameraTwoOpen"
                        style="
                          position: absolute;
                          bottom: 0;
                          left: 0;
                          right: 0;
                          text-align: center;
                          width: 100%;
                        "
                      >
                        <button
                          @click="takePicture(2)"
                          type="button"
                          class="btn btn-dark"
                          style="
                            border: solid 3px #f59c1a;
                            width: 50px;
                            height: 50px;
                            line-height: 25px;
                            text-align: center;
                            color: white;
                            cursor: pointer;
                            font-size: 16px;
                            border-radius: 50%;
                            margin-top: -75px;
                            -webkit-border-radius: 50%;
                          "
                        >
                          <i class="fa fa-camera"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div
                    v-if="hasilFotoSketsa.length > 0"
                    class="row mt-1"
                    style="padding: 5px"
                  >
                    <div
                      class="col-md-12"
                      v-for="(image, index) in hasilFotoSketsa"
                      :key="index"
                    >
                      <div class="image-groups">
                        <div class="image-container">
                          <img
                            :src="image.url"
                            :alt="image.name"
                            class="image-item"
                          />
                          <span>{{ image.name }}</span>
                        </div>
                        <div class="button-container">
                          <button
                            class="tombol-hapus"
                            @click="deletePicture(index, 2)"
                          >
                            <i class="fa fa-times"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button
                class="btn btn-lg btn-warning pull-right"
                @click="showModal"
                variant="primary"
                style="border-radius: 10px"
              >
                Submit
              </button>
              <a
                href="javascript:window.history.go(-1);"
                class="btn btn-lg btn-grey"
                style="border-radius: 10px"
                >Kembali</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <b-modal v-model="modalVisible" @hide="beforeModalClose">
      <template #modal-header>
        <h5 class="modal-title">Disclaimer</h5>
        <button
          type="button"
          @click="closeModal"
          class="btn btn-danger btn-sm btn-icon btn-circle"
          data-dismiss="modal"
          aria-hidden="true"
        >
          ×
        </button>
      </template>
      <div>
        <div class="alert alert-warning">
          <p class="text-justify">
            Perlu diperhatikan bahwa kelalaian dalam memberikan informasi yang
            akurat dan lengkap akan mengakibatkan denda sebesar 100 kali lipat
            dari jumlah pembayaran yang seharusnya. Kami menegaskan bahwa adalah
            tanggung jawab penuh pelanggan untuk memastikan bahwa semua data
            yang diberikan telah diverifikasi dengan benar. Denda akan
            diberlakukan tanpa pengecualian sesuai dengan ketentuan yang
            berlaku.
          </p>
        </div>
      </div>
      <template #modal-footer>
        <button
          @click="postData"
          class="btn btn-block btn-warning"
          variant="primary"
          :disabled="loading"
        >
          <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
          Daftar
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import PageOptions from "../../config/PageOptions.vue";
import moment from "moment";

export default {
  data() {
    return {
      form: {
        customer_id: "",
        identity_number: "",
        npwrd: "",
        customer_name: "",
        customer_address: "",
        district: "",
        village: "",
        hamlet: "",
        neighbourhood: "",
        customer_categories: "",
        created_by: "",
        created_at: "",
        longitude: "",
        latitude: "",
        identity_status: "",
        employment_status: "",
        institutions: "",
        phone_number: "",
      },
      loading: false,
      showUserAccess: true,
      formValidate: [],
      categoiresList: [],
      districtList: [],
      villageList: [],
      instansiList: [],

      isLoading: false,
      searchRemark: "",
      dataRemark: [],
      showPotensial: false,
      modalVisible: false,
      conditionMet: false,

      kameraFotoLokasi: null,
      kameraFotoSketsa: null,
      hasilFotoLokasi: [],
      hasilFotoSketsa: [],
      currentCamera: "environment",
      cameraOneOpen: false,
      cameraTwoOpen: false,
    };
  },
  mounted() {
    this.fetchCategories();
    this.fetchDistricts();
    this.fetchInstansi();
    this.getLocation();
  },
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    showModal() {
      this.modalVisible = true;
      this.conditionMet = false;
    },
    closeModal() {
      this.modalVisible = false;
      this.conditionMet = true;
    },
    beforeModalClose(evt) {
      if (!this.conditionMet) {
        evt.preventDefault();
      }
    },
    postData() {
      this.loading = true;
      if (this.form.identity_status == "") {
        this.formValidate.identity_status = "Pilih Jenis Identitas !";
        this.loading = false;
        this.closeModal();
      } else {
        const formData = new FormData();
        for (let key in this.form) {
          formData.append(key, this.form[key]);
        }
        if (this.hasilFotoSketsa.length > 0) {
          formData.append("image_house", this.hasilFotoSketsa[0].url);
        }
        axios
          .post("/v1/customers/registrer/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              token: this.userToken,
            },
          })
          .then((response) => {
            if (response.data.validasi == false) {
              this.loading = false;
              this.formValidate = response.data.message;
            } else {
              this.loading = false;
              this.closeModal();
              Swal.fire({
                title: response.data.title,
                text: response.data.message,
                icon: response.data.icon,
                showCancelButton: false,
                showConfirmButton: false,
              });
              setTimeout(() => {
                Swal.close();
                if (response.data.status) {
                  this.$router.go(-1);
                }
              }, 700);
            }
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
    },
    fetchCategories() {
      axios
        .get("/v1/customer_categories", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.categoiresList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchInstansi() {
      axios
        .get("/v1/government_agencies", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.instansiList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchDistricts() {
      axios
        .get("/v1/daerah/kecamatan/7271", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.districtList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchVillages(event) {
      axios
        .get("/v1/daerah/kelurahan/" + event.target.value, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.villageList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.showError
        );
      } else {
        alert("Geolokasi tidak didukung di browser ini.");
      }
    },
    showPosition(position) {
      this.form.latitude = position.coords.latitude;
      this.form.longitude = position.coords.longitude;
    },
    showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          alert("Akses ke lokasi ditolak oleh pengguna.");
          break;
        case error.POSITION_UNAVAILABLE:
          alert("Informasi lokasi tidak tersedia.");
          break;
        case error.TIMEOUT:
          alert("Waktu permintaan lokasi habis.");
          break;
        case error.UNKNOWN_ERROR:
          alert("Terjadi kesalahan yang tidak diketahui.");
          break;
      }
    },
    validasiNik() {
      this.formValidate.identity_number = "";
      if (this.form.identity_status == "1") {
        if (this.form.identity_number.length < 16) {
          this.formValidate.identity_number =
            "NIK Kurang " +
            (16 - this.form.identity_number.length) +
            " Digit !";
        } else if (this.form.identity_number.length > 16) {
          this.formValidate.identity_number =
            "NIK Kelebihan " +
            (this.form.identity_number.length - 16) +
            " Digit !";
        }
      } else if (this.form.identity_status == "2") {
        if (this.form.identity_number.length < 13) {
          this.formValidate.identity_number =
            "NIB Kurang " +
            (13 - this.form.identity_number.length) +
            " Digit !";
        } else if (this.form.identity_number.length > 13) {
          this.formValidate.identity_number =
            "NIB Kelebihan " +
            (this.form.identity_number.length - 13) +
            " Digit !";
        }
      }
    },
    async toggleCamera(param) {
      await this.stopCamera(param);
      this.currentCamera =
        this.currentCamera === "environment" ? "user" : "environment";
      await this.startCamera(param);
    },
    async startCamera(param) {
      try {
        if (param == 1) {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: this.currentCamera },
          });
          this.$refs.kameraLokasi.srcObject = stream;
          this.kameraFotoLokasi = stream;
          this.cameraOneOpen = true;
          this.stopCamera(2);
        } else {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: this.currentCamera },
          });
          this.$refs.kameraSketsa.srcObject = stream;
          this.kameraFotoSketsa = stream;
          this.cameraTwoOpen = true;
          this.stopCamera(1);
        }
      } catch (error) {
        console.error("Gagal membuka kamera:", error);
      }
    },
    stopCamera(param) {
      if (param == 1) {
        if (this.kameraFotoLokasi) {
          const tracks = this.kameraFotoLokasi.getTracks();
          tracks.forEach((track) => {
            track.stop();
            // track.enabled = false;
          });
          this.cameraOneOpen = false;
          this.kameraFotoLokasi = null;
        }
      }
      if (param == 2) {
        if (this.kameraFotoSketsa) {
          const tracks = this.kameraFotoSketsa.getTracks();
          tracks.forEach((track) => {
            track.stop();
            // track.enabled = false;
          });
          this.cameraTwoOpen = false;
          this.kameraFotoSketsa = null;
        }
      }
    },
    takePicture(param) {
      if (param === 1) {
        const video = this.$refs.kameraLokasi;
        const canvas = this.$refs.canvasLokasi;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        // Gambar video ke dalam canvas
        const context = canvas.getContext("2d");
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        // Ambil data gambar dari canvas dan tambahkan ke daftar
        const imageDataURL = canvas.toDataURL("image/png");
        const timestamp = Date.now();
        const imageName = `gambar_${timestamp}.png`;

        this.hasilFotoLokasi.push({ url: imageDataURL, name: imageName });
      }
      if (param === 2) {
        const video2 = this.$refs.kameraSketsa;
        const canvas2 = this.$refs.canvasSketsa;
        canvas2.width = video2.videoWidth;
        canvas2.height = video2.videoHeight;

        // Gambar video ke dalam canvas
        const context2 = canvas2.getContext("2d");
        context2.drawImage(video2, 0, 0, video2.videoWidth, video2.videoHeight);

        // Ambil data gambar dari canvas dan tambahkan ke daftar
        const imageDataURL = canvas2.toDataURL("image/png");
        const timestamp = Date.now();
        const imageName = `gambar_${timestamp}.png`;

        // Memeriksa apakah objek ditemukan berdasarkan indeks
        if (this.hasilFotoSketsa.length > 0) {
          // Mengganti nilai objek dengan nilai baru
          this.hasilFotoSketsa.splice(0, 1);
          this.hasilFotoSketsa.push({ url: imageDataURL, name: imageName });
        } else {
          // Jika objek tidak ditemukan, menambahkan objek baru ke array
          this.hasilFotoSketsa.push({ url: imageDataURL, name: imageName });
        }
      }

      const audio = this.$refs.shutter;
      if (audio.paused) {
        audio.play();
      } else {
        audio.pause();
      }

      // Pastikan ukuran canvas sesuai dengan ukuran video
    },
    deletePicture(index, param) {
      if (param == 1) {
        this.hasilFotoLokasi.splice(index, 1);
      } else {
        this.hasilFotoSketsa.splice(index, 1);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((track) => track.stop());
    }
  },
};
</script>
<style lang="css" scoped>
@font-face {
  font-family: "Saira";
  src: url("../../assets/font/saira.ttf") format("truetype");
}
.card-footer {
  background-color: #fff;
  border-color: #fff;
  border-radius: 10px;
  margin: 10px;
  padding: 5px;
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  background-color: #e94f07;
  /* color: #fff; */
  border-radius: 0px 0px 30px 30px;
  margin-bottom: -130px;
  z-index: 0;
}
.container {
  padding: 20px;
}
@import "/css/front-end/app.min.css";

.footers {
  background-color: #2b2b2b;
  color: #fff;
  position: sticky;
  top: 100%;
}
.copy {
  border-top: solid 0.15em #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: light;
  font-size: 14px;
  padding: 20px;
  margin: 0 3em;
}
.card-menu {
  background-color: #fdfdfd;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 40vh;
}

.image-groups {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  border-radius: 5px;
}

/* Image styling */
.image-container {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
}

.image-item {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  object-fit: cover;
  margin-right: 5px;
}

.tombol-hapus {
  float: right;
  color: rgb(255, 0, 0);
  border-radius: 3px;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}
.tombol-kirim {
  float: right;
  color: #fff;
  border-radius: 3px;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0098fe;
}
</style>